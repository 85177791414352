import React, { useState } from "react";
import { Range } from "react-range";
import styled from "styled-components";

const Track = styled.div`
  height: 2px;
  width: 100%;
  background: #103c96;
  border-radius: 1px;
`;

const Thumb = styled.div`
  height: 16px;
  width: 16px;
  background: #103c96;
  border-radius: 8px;

  &:focus {
    box-shadow: 0 0 0 1px #103c96;
    outline: none;
  }
`;

export const RangeInput = ({ step = 1, min = 0, max = 100, ...props }) => {
  return (
    <Range
      step={step}
      min={min}
      max={max}
      {...props}
      renderTrack={({ props, children }) => (
        <Track {...props}>{children}</Track>
      )}
      renderThumb={({ props }) => <Thumb {...props} />}
    />
  );
};

export default RangeInput;
