import React from "react";
import styled from "styled-components";


export const Button = styled.a`
  display: inline-block;
  background: ${props =>
    props.primary ? props.theme.colors.primary : props.theme.colors.white};
  color: ${props =>
    props.primary ? props.theme.colors.white : props.theme.colors.primary};
  border: 1px solid ${props => props.theme.colors.primary};

  border-radius: ${props => props.theme.spacer * 2}px;
  padding: ${props => props.theme.spacer * 5}px;
  font-weight: bold;
  text-align: center;

  text-decoration: none;

  :hover {
    cursor: pointer;
  }

  :focus {
    box-shadow: 0 0 0 1px ${props => props.theme.colors.primary};
    outline: none;
  }

  .play {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 8px 12px;
    border-color: transparent transparent transparent #007bff;
  }
`;

export const BlurButton = styled(Button)`
  background: rgba(255, 255, 255, 0.2);
  color: white;
  border-color: white;
  display: flex;
  padding: 10px 20px !important;

  svg {
    margin-right: 10px;
    width: 16px;
    height: 16px;
    path {
      fill: white;
    }
  }
`;

export const BuyButton = styled(Button)`
  border: 1px solid ${props => props.theme.colors.white};
  box-shadow: 0 8px 16px -12px ${props => props.theme.colors.primary};
  grid-column-start: 7;
  grid-column-end: 10;
`;

export const OrderButton = styled(Button)`
  box-shadow: 0 8px 16px -12px ${props => props.theme.colors.primary};
  grid-column-start: 10;
  grid-column-end: 13;
`;