import React from "react";
import styled, { css } from "styled-components";
import { navigate } from "gatsby";
import Grid from "styled-components-grid";

import { BuyButton, OrderButton } from "./button";

const grid = css`
  display: grid;
  grid-gap: ${props => props.theme.spacer * 8}px;
  grid-template-columns: repeat(12, 1fr);

  @media (max-width: ${props => props.theme.breakpoints.s}px) {
    grid-gap: ${props => props.theme.spacer * 2}px;
  }
`;

const Header = styled.header`
  margin-top: ${props => props.theme.spacer * 6}px;
  ${grid}
`;

const Logo = styled.a`
  grid-column-start: 1;
  grid-column-end: 3;
  font-size: ${props => props.theme.baseFontSize * 1.5}px;
  text-decoration: none;
  color: ${props => props.theme.colors.primary};
  font-weight: bold;
  align-self: center;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    flex-direction: column;
  }
`;

export default () => (
  <Header>
    <Logo
      href="/"
      onClick={e => {
        navigate("/");
        e.preventDefault();
      }}
    >
      BlueJay
    </Logo>
    <BuyButton
      href="/"
      onClick={e => {
        navigate("/");
        e.preventDefault();
      }}
    >
      Buy Stock Music
    </BuyButton>
    <OrderButton
      primary
      href="/order-custom-music/"
      onClick={e => {
        navigate("/order-custom-music/");
        e.preventDefault();
      }}
    >
      Order Custom Music
    </OrderButton>
  </Header>
);
