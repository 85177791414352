module.exports = [{
      plugin: require('/Users/chf/workspace/mociepka/bluejay/site/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/icon.png","name":"Bluejay Music Studio","short_name":"Bluejay","start_url":"/","background_color":"#103c96","theme_color":"#103c96","display":"standalone"},
    },{
      plugin: require('/Users/chf/workspace/mociepka/bluejay/site/node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"analytics":true,"performance":true},"credentials":{"apiKey":"AIzaSyAjBP3nDq88H3JcxaFqkPj2Kb43KIdS7qg","authDomain":"bluejay-studio.firebaseapp.com","databaseURL":"https://bluejay-studio.firebaseio.com","projectId":"bluejay-studio","storageBucket":"bluejay-studio.appspot.com","messagingSenderId":"240298594257","appId":"1:240298594257:web:6bb66a18c84c381fb2daf0","measurementId":"G-JEQRVCKCT5"}},
    },{
      plugin: require('/Users/chf/workspace/mociepka/bluejay/site/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PQ37L8X","includeInDevelopment":true},
    },{
      plugin: require('/Users/chf/workspace/mociepka/bluejay/site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
