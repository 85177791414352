import React from "react";
import styled from "styled-components";


export const Footer = styled.footer`
  font-weight: bold;
  text-align: center;
  padding: 32px;
  box-shadow: 0 -10px 40px 0 rgba(16, 60, 150, 0.05);

  &.player-on {
    margin-bottom: 90px;
  }
`;

export default Footer;