import React, { useState, useEffect } from "react";
import styled from "styled-components";

import RangeInput from "./range";

import PlayIcon from "../assets/play.svg";
import PauseIcon from "../assets/pause.svg";
import VolumeMuteIcon from "../assets/volume-mute.svg";
import VolumeIcon from "../assets/volume.svg";

export const PlayerContext = React.createContext();

export const PlayerProvider = ({ children }) => {
  const [currentTrack, setTrack] = useState();
  const [autoPlay, setAutoPlay] = useState(false);
  /*
    track = title, coverUrl, streamUrl, category
    */
  const state = {
    play: track => {
      setTrack(track);
      setAutoPlay(true);
    },
    track: currentTrack,
    volume: 100,
    autoPlay
  };
  return (
    <PlayerContext.Provider value={state}>{children}</PlayerContext.Provider>
  );
};

const Player = styled.div`
  box-shadow: 0 -10px 40px 0 rgba(16, 60, 150, 0.05);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  padding: 16px;

  .wrapper {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > * {
      margin-right: 20px;
    }
  }

  .cover {
    height: 48px;
  }

  .progress {
    width: 300px;
  }

  .volume {
    width: 200px;
    display: flex;
    align-items: center;

    button {
      border: none;
      background: none;
      width: 24px;
      height: 24px;
      padding: 0;
      margin-right: 12px;
      border-radius: 100%;
      &:focus {
        box-shadow: 0 0 0 1px #103c96;
        outline: none;
      }
      cursor: pointer;
      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .play-pause button {
    border: none;
    background: none;
    width: 50px;
    height: 50px;
    padding: 0;
    cursor: pointer;

    border-radius: 100%;
      &:focus {
        box-shadow: 0 0 0 1px #103c96;
        outline: none;
      }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .time,
  .total {
    font-variant-numeric: tabular-nums;
  }
`;

export const PlayerWrapper = ({
  streamUrl,
  autoPlay,
  coverUrl,
  title,
  category,
  ...props
}) => {
  const [duration, setDuration] = useState(0);
  const [time, setTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(autoPlay);
  const [volume, setVolume] = useState(100);
  const [beforeMuteVolume, setBeforeMuteVolume] = useState(100);
  const progress = Math.round(duration ? (time * 100) / duration : 0);
  const audioRef = React.createRef();
  const play = () => audioRef.current.play();
  const pause = () => audioRef.current.pause();

  useEffect(() => {
    audioRef.current.volume = volume / 100;
  }, [volume]);

  const prettyTime = time => {
    let hours = Math.floor(time / 3600);
    let mins = "0" + Math.floor((time % 3600) / 60);
    let secs = "0" + Math.floor(time % 60);

    mins = mins.substr(mins.length - 2);
    secs = secs.substr(secs.length - 2);

    if (!isNaN(secs)) {
      if (hours) {
        return `${hours}:${mins}:${secs}`;
      }
      return `${mins}:${secs}`;
    }
    return "00:00";
  };

  const changeTime = percentageValue => {
    const time = (percentageValue / 100) * duration;
    audioRef.current.currentTime = time;
  };

  return (
    <Player>
      <div className="wrapper">
        <img className="cover" src={coverUrl} />
        <div className="info">
          <b>{title}</b>
          <br />
          <span>{category}</span>
        </div>
        <div className="play-pause">
          {isPlaying ? (
            <button onClick={pause}>
              <PauseIcon />
            </button>
          ) : (
            <button onClick={play}>
              <PlayIcon />
            </button>
          )}
        </div>
        <div className="time">{prettyTime(time)}</div>
        <div className="progress">
          <RangeInput
            values={[progress]}
            onChange={([value]) => {
              changeTime(value);
            }}
          />
        </div>
        <div className="total">{prettyTime(duration)}</div>
        <div className="volume">
          {volume ? (
            <button
              onClick={() => {
                setBeforeMuteVolume(volume);
                setVolume(0);
              }}
            >
              <VolumeIcon />
            </button>
          ) : (
            <button onClick={() => setVolume(beforeMuteVolume)}>
              <VolumeMuteIcon />
            </button>
          )}
          <RangeInput
            values={[volume]}
            onChange={([value]) => {
              setVolume(value);
            }}
          />
        </div>

        <audio
          {...props}
          ref={audioRef}
          onTimeUpdate={e => setTime(e.currentTarget.currentTime)}
          onLoadedData={e => setDuration(e.currentTarget.duration)}
          onVolumeChange={e =>
            setVolume(Math.round(e.currentTarget.volume * 100))
          }
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          autoPlay={autoPlay}
        >
          <source src={streamUrl} type="audio/mpeg" />
        </audio>
      </div>
    </Player>
  );
};

export default PlayerWrapper;
