import React from "react";
import styled, {
  css,
  createGlobalStyle,
  ThemeProvider
} from "styled-components";
import styledNormalize from "styled-normalize";
import { useFirebase } from "gatsby-plugin-firebase";
import { Helmet } from "react-helmet";

import Player, { PlayerContext, PlayerProvider } from "./player";
import Header from "./header";
import Footer from "./footer";

const spacer = 4;

const theme = {
  colors: {
    base: "#4a4a4a",
    primary: "#103c96",
    white: "#fff"
  },
  spacer,
  baseFontSize: spacer * 4,
  gutter: spacer * 8,
  breakpoints: {
    xl: 1140,
    l: 960,
    m: 720,
    s: 540
  }
};

export const grid = css`
  display: grid;
  grid-gap: ${props => props.theme.gutter}px;
  grid-template-columns: repeat(12, 1fr);
`;

const Layout = styled.main`
  margin: 0 auto;
  max-width: calc(${props => props.theme.breakpoints.xl - 2 * props.theme.spacer }px - 10px);
  padding: ${props => 2 * props.theme.spacer}px;
  /* display: grid; */
  /* grid-gap: 1rem; */
  /* grid-template-columns: repeat(12, 1fr); */
`;

const GlobalStyle = createGlobalStyle`
    ${styledNormalize}
    body {
        color: ${props => props.theme.colors.base};
        font-family: Montserrat;
        font-size: ${props => props.theme.baseFontSize}px;
    }

    h1 {
        font-size: ${props => props.theme.baseFontSize * 2.5}px;
        font-weight: 900;
    }

    a {
        color: ${props => props.theme.colors.primary};
    }
`;

export default ({ children }) => {
  useFirebase(firebase => {
    firebase.analytics();
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <PlayerProvider>
          <Layout>
            <Helmet>
              <title>Bluejay Music Studio</title>
              <meta name="description" content="Stock music, game music" />
              <meta name="google-site-verification" content="6e6OmoaRvg0Op-TafapkemT3sp1pZoTyybQPGgYAwKs" />
            </Helmet>
            <GlobalStyle />
            <Header />
            {children}
          </Layout>
          <PlayerContext.Consumer>
            {({ track, autoPlay }) => (
              <>
                <Footer className={track ? "player-on" : null}>
                  contact@bluejay.studio
                </Footer>
                {track ? (
                  <Player
                    title={track.title}
                    category={track.category}
                    // TODO: That shouldn't work streamUrl is proper one...
                    src={track.streamUrl}
                    coverUrl={track.coverUrl}
                    autoPlay={autoPlay}
                  />
                ) : null}
              </>
            )}
          </PlayerContext.Consumer>
        </PlayerProvider>
      </ThemeProvider>
    </>
  );
};
